<template>
    <div class="bgc_fff mb_20 w100">
        <div class="w100 bs ">
            <div class="w100 bs " style="padding: 24px;">
                <div class="flex pb_24">
                    <div class="shrink_0" style="display: block;width: 100px;height: 112px;margin-right: 14px;">
                        <el-image style="width: 100px; height: 100px" :src="rInfo.ext ? rInfo.ext.userPhoto : ''"
                            fit="contain" />
                    </div>
                    <div class="flex-sub" style="position: relative;">
                        <div class="w100 bs flex align-center justify-between mb_4" style="height: 37px;">
                            <div class="flex align-center">
                                <div class="text-24 resume_name">
                                    {{ rInfo.name }}
                                </div>
                                <!-- <div class="resume_label">离职，随时可以面试</div> -->
                            </div>
                            <div class="flex align-center c_1890ff text-14">
                                <div class="flex align-center">
                                    <!-- <div class="el-icon-download text-16"></div> -->
                                    <!-- <span style="margin-left: 10px;">保存到本地</span> -->
                                </div>
                            </div>
                        </div>
                        <div class="c_272727 text-14 mb_4" style="line-height: 22px;">
                            <span class="css-1cgrbob eyp4si65">{{ rInfo.ext?.age }}岁</span>
                            <span class="css-1cgrbob eyp4si65">{{ rInfo.ext ? rInfo.ext.liveCityRemarks : '' }}</span>
                            <span class="css-1cgrbob eyp4si65">{{ rInfo.ext?.workYears }}年经验</span>
                        </div>
                        <div class="c_272727 text-14 mb_4" style="line-height: 22px;"
                            v-if="rInfo.ext && rInfo.ext.resumeWorkList && rInfo.ext.resumeWorkList.length > 0">
                            <span class="css-1cgrbob eyp4si65">{{ rInfo.ext.resumeWorkList[0].company }}</span>
                            <span class="css-1cgrbob eyp4si65">{{ rInfo.ext.resumeWorkList[0].job }}</span>
                        </div>
                        <div class="c_272727 text-14 flex align-center">
                            <span class="css-1cgrbob eyp4si65 flex align-center">
                                <span class="el-icon-mobile-phone c_1890ff text-16"></span>--
                            </span>
                            <span class="css-1cgrbob eyp4si65 flex align-center">
                                <span class="el-icon-message c_1890ff text-16"></span>--
                            </span>
                        </div>
                        <div style="position: absolute;top: 40px;right: 2px;">
                            <el-button type="primary" size="small" @click="showChat">和TA聊聊</el-button>
                        </div>
                    </div>
                </div>

                <div class="df_intention w100 bs">
                    <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                        <img src="@/assets/icon_jl_01.png" class="df_icon" alt="">
                        <div class="text-18 text-bold c_101010">求职意向</div>
                    </div>
                    <div class="w100 bs df_con_ul c_272727 text-14">
                        <div class="w100 df_con_li" v-for="(item, index) in rInfo.resumeIntendList" :key="index">
                            <div class="mb_20 w100 flex">
                                <div class="w50">期望职位：{{ item.ext.categoryName }}</div>
                                <div class="w50">期望薪资：{{ item.salaryMin / 1000 }}-{{ item.salaryMax / 1000 }}K</div>
                            </div>
                            <div class="w100 flex">
                                <div class="w50">工作地点：{{ item.ext.liveCityName }}</div>
                                <div class="w50">工作类型：{{ nlist[item.nature - 1] }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="df_intention w100 bs">
                    <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                        <img src="@/assets/icon_jl_02.png" class="df_icon" alt="">
                        <div class="text-18 text-bold c_101010">工作经历</div>
                    </div>
                    <div class="w100 bs df_con_ul c_272727 text-14">
                        <div class="w100 bs css-rktgro" v-for="(item, index) in rInfo.resumeWorkList" :key="index">
                            <div class="flex align-center c_101010 text-bold">
                                <div class="text-16 mr_30">{{ item.company }}</div>
                                <div class="mr_30">{{ item.job }}</div>
                                <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                            </div>
                            <div class="mt_20 w100 bs flex">
                                <div class="shirnk_0">职位描述：</div>
                                <div class="flex-sub">{{ item.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="df_intention w100 bs">
                    <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                        <img src="@/assets/icon_jl_03.png" class="df_icon" alt="">
                        <div class="text-18 text-bold c_101010">项目经验</div>
                    </div>
                    <div class="w100 bs df_con_ul c_272727 text-14">
                        <div class="w100 bs css-rktgro" v-for="(item, index) in rInfo.resumeProjectList" :key="index">
                            <div class="flex align-center c_101010 text-bold">
                                <div class="text-16 mr_30">{{ item.name }}</div>
                                <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                            </div>
                            <div class="mt_20 w100 bs flex">
                                <div class="shirnk_0">项目描述：</div>
                                <div class="flex-sub">{{ item.description }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="df_intention w100 bs">
                    <div class="w100 flex align-center" style="height: 30px;line-height: 30px;">
                        <img src="@/assets/icon_jl_04.png" class="df_icon" alt="">
                        <div class="text-18 text-bold c_101010">教育经历</div>
                    </div>
                    <div class="w100 bs df_con_ul c_272727 text-14">
                        <div class="w100 bs css-rktgro" v-for="(item, index) in rInfo.resumeEducationList" :key="index">
                            <div class="flex align-center c_101010 text-bold">
                                <div class="text-16 mr_30">{{ item.name }}</div>
                                <!-- <div class="mr_30">大专</div> -->
                                <div class="mr_30">{{ item.major }}</div>
                                <div>{{ item.startTime }}&nbsp;-&nbsp;{{ item.endTime }}</div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
        </div>
        <dfpop ref="dfpopref" @confirm="popconfirm" />
    </div>
</template>
<script lang="ts">
import { reactive, toRefs, defineComponent, ref } from 'vue';
import { getSeekerResumeInfo, createChatInfo, listHunterJob, viewJobSeeker } from '@/util/apis'
import router from "@/router";
import dfpop from "@/views/components/dfPop.vue"
export default defineComponent({
    name: '',
    components: {
        dfpop
    },
    setup: () => {
        const state = reactive({
            rInfo: {} as any,
            jobList: [] as any,
            nlist: ['全职', '兼职', '实习', '校招']
        })
        getSeekerResumeInfo({
            id: router.currentRoute.value.params.id
        }).then(res => {
            if (res.code == '0') {
                res.data.resumeIntendList = res.data.ext.resumeIntendList;
                res.data.resumeWorkList = res.data.ext.resumeWorkList;
                res.data.resumeEducationList = res.data.ext.resumeEducationList;
                res.data.resumeProjectList = res.data.ext.resumeProjectList;
                state.rInfo = res.data;
                viewJobSeeker({
                    seekerUserId: res.data.userId
                }).then(res => {

                })
            }
        })
        listHunterJob({
            page: 1,
            pageSize: 1000,
            state: '1',
        }).then(res => {
            res.data.list.forEach((element: any) => {
                element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
            });
            state.jobList = res.data.list;
        })

        const dfpopref = ref(null);
        const showChat = (ClickIndex: any) => {
            console.log('dfpopref', dfpopref);
            (dfpopref as any).value.show(state.jobList);
        }

        const popconfirm = (i: any) => {
            createChatInfo({
                seekerUserId: state.rInfo.userId, jobId: state.jobList[i].id
            }).then(res => {
                if (res.code == 0) {
                    router.push({ path: "/news/index" });
                }
            })
        }
        return {
            ...toRefs(state), popconfirm, showChat, dfpopref
        }
    }
})
</script>

<style scoped>
@import "./index.css";
</style>