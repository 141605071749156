<template>
    <!-- 对话框组件 -->
    <teleport to="body">
        <transition name="confirm-fade">
            <div class="confirm" v-show="visible">
                <div class="confirm-wrapper">
                    <div class="confirm-content">
                        <div class="flex align-center justify-between w100 bs"
                            style="padding: 16px;border-bottom: 1px solid #f0f0f0;">
                            <div>请选择需要沟通的职位</div>
                            <div class="text-18 el-icon-close cur-p" @click="hide" style="color:#333;"></div>
                        </div>
                        <div class="w100 bs" style="padding: 24px;">
                            <el-radio-group v-model="radio1">
                                <el-radio :label="index" v-for="(item, index) in list" :key="index" size="large"
                                    style="margin-bottom:20px;">{{ item.name }} | {{ item.cusCity }} |
                                    {{ item.salaryMin / 1000 }}-{{ item.salaryMax / 1000 }}K/月 </el-radio>

                            </el-radio-group>
                        </div>
                        <div class="flex align-center justify-end w100 bs"
                            style="padding: 16px;border-top: 1px solid #f0f0f0;">
                            <el-button size="small" style="margin-right:20px;" @click="hide">取消</el-button>
                            <el-button type="primary" size="small" @click="confirm">确认</el-button>
                        </div>
                    </div>
                </div>
            </div>
        </transition>
    </teleport>
</template>

<script lang="ts">
import { defineComponent } from "vue";
export default defineComponent({
    name: 'confirm',
    props: {
        text: {
            type: String,
            default: ''
        },
        confirmBtnText: {
            type: String,
            default: '确定'
        },
        cancelBtnText: {
            type: String,
            default: '取消'
        },

    },
    data() {
        return {
            visible: false,
            radio1: -1,
            list: [] as any
        }
    },
    methods: {
        confirm() {
            if (this.radio1 != -1) {
                this.hide()
                this.$emit('confirm',this.radio1)
            }

        },
        // 取消
        cancel() {
            this.hide()
            this.$emit('cancel')
        },
        hide() {
            this.visible = false
        },
        show(list: any) {
            this.list = list;
            this.visible = true
        }
    }
})
</script>

<style scoped >
.confirm {
    position: fixed;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: 996;
    background-color: rgba(0, 0, 0, 0.7);
}

.confirm-wrapper {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 997;


}

.confirm-content {
    width: 550px;
    border-radius: 4px;
    background: #fff;

}
</style>