
import { defineComponent } from "vue";
export default defineComponent({
    name: 'confirm',
    props: {
        text: {
            type: String,
            default: ''
        },
        confirmBtnText: {
            type: String,
            default: '确定'
        },
        cancelBtnText: {
            type: String,
            default: '取消'
        },

    },
    data() {
        return {
            visible: false,
            radio1: -1,
            list: [] as any
        }
    },
    methods: {
        confirm() {
            if (this.radio1 != -1) {
                this.hide()
                this.$emit('confirm',this.radio1)
            }

        },
        // 取消
        cancel() {
            this.hide()
            this.$emit('cancel')
        },
        hide() {
            this.visible = false
        },
        show(list: any) {
            this.list = list;
            this.visible = true
        }
    }
})
