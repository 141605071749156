
import { reactive, toRefs, defineComponent, ref } from 'vue';
import { getSeekerResumeInfo, createChatInfo, listHunterJob, viewJobSeeker } from '@/util/apis'
import router from "@/router";
import dfpop from "@/views/components/dfPop.vue"
export default defineComponent({
    name: '',
    components: {
        dfpop
    },
    setup: () => {
        const state = reactive({
            rInfo: {} as any,
            jobList: [] as any,
            nlist: ['全职', '兼职', '实习', '校招']
        })
        getSeekerResumeInfo({
            id: router.currentRoute.value.params.id
        }).then(res => {
            if (res.code == '0') {
                res.data.resumeIntendList = res.data.ext.resumeIntendList;
                res.data.resumeWorkList = res.data.ext.resumeWorkList;
                res.data.resumeEducationList = res.data.ext.resumeEducationList;
                res.data.resumeProjectList = res.data.ext.resumeProjectList;
                state.rInfo = res.data;
                viewJobSeeker({
                    seekerUserId: res.data.userId
                }).then(res => {

                })
            }
        })
        listHunterJob({
            page: 1,
            pageSize: 1000,
            state: '1',
        }).then(res => {
            res.data.list.forEach((element: any) => {
                element.cusCity = element.ext.cityList.map((item: any) => item.remarks).join('、')
            });
            state.jobList = res.data.list;
        })

        const dfpopref = ref(null);
        const showChat = (ClickIndex: any) => {
            console.log('dfpopref', dfpopref);
            (dfpopref as any).value.show(state.jobList);
        }

        const popconfirm = (i: any) => {
            createChatInfo({
                seekerUserId: state.rInfo.userId, jobId: state.jobList[i].id
            }).then(res => {
                if (res.code == 0) {
                    router.push({ path: "/news/index" });
                }
            })
        }
        return {
            ...toRefs(state), popconfirm, showChat, dfpopref
        }
    }
})
